import axios from "axios";

export class HttpClient {
    constructor() {
        this._client = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL_API,
        headers: {
            company: process.env.VUE_APP_COMPANY_ID
        }})
        this._client.interceptors.response.use(
            response => {
                return response
            },
            error => {
                throw error.response.data.message ?? "Hubo un error, intenta nuevamente"
            }
        )
    }

    static getInstance() {
        if (!HttpClient._instance) {
            HttpClient._instance = new HttpClient()
        }
        return HttpClient._instance
    }

    async get(path, params) {
        return (await this._client.get(path, { params })).data
    }

    async post(path, body, configs) {
        return (await this._client.post(path, body, configs)).data
    }

    async put(path, body, configs) {
        return (await this._client.put(path, body, configs)).data
    }

    async delete(path, params) {
        return (await this._client.delete(path, { params })).data
    }
}
