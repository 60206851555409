import axios from "axios";
import { HttpClient } from '../services/http/index';

const BASE_URL_CF = process.env.VUE_APP_CF_D2C

const http = HttpClient.getInstance();

export const zoneCosts = async (zipCode) => {
    try {
        const { data } = await axios.get(BASE_URL_CF + '/AIG_D2C_AON_MARSH_GET_ZONE?zipCode=' + zipCode)
        return data
    } catch (error) {
        throw error
    }
}

export const emiliPayments = async (body) => {
    try {
        const data = await http.post('/payments/processPayment', body)
        return data
    } catch (error) {
        console.log("error: " + error);
        throw error
    }
}

export const getPlans = async (planId, secureAmount, zona_hidro, zona_sismica, dataCustomer, companyId) => {
    try {
        const body = {
            planId, secureAmount, zona_hidro, zona_sismica, dataCustomer, companyId
        }
        const data = await http.post('/quotes/getPlansQuotation', body)
        return data
    } catch (error) {
        throw error
    }
}

export const getQuotePDF = async (quoteId, zipCode, catastrofico, companyId) => {
    try {
        return await http.post('/quotes/downloadQuotePdf', { quoteId, zipCode, catastrofico, companyId }, {
            responseType: "blob",
        });
    } catch (error) {
        throw error
    }
}

export const convertToDollar = async () => {
    try {
        const response = await axios.get("https://openexchangerates.org/api/latest.json?app_id=a50fae975e3349f9bf6b1c480aeb5512");
        const values = response.data;
        const actualRateMXN = values.rates.MXN;
        return actualRateMXN;
    } catch (error) {
        throw error.message;
    }
}

export const saveProgressStatus = async (step, quoteId = null, company) => {
    try {
        const response = await axios.post(`${BASE_URL_CF}/AIG_D2C_HOGAR_STANDARD_TRACK_USER`, { step, quoteId, company })
        return response.data.quoteId;
    } catch (error) {
        throw error.message;
    }
}